import React, { useEffect, useState } from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import Lightbox from 'react-image-lightbox';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import Box from '@mui/material/Box';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import Button from '@mui/material/Button';

import Grid from '@mui/material/Grid';
import Main from 'layouts/Main';

// import p1 from 'assests/Photos/ClassAssembly/2024/10A/1.jpg';
// import p2 from 'assests/Photos/ClassAssembly/2024/10A/2.jpg';
// import p3 from 'assests/Photos/ClassAssembly/2024/10A/3.jpg';
// import p4 from 'assests/Photos/ClassAssembly/2024/10A/4.jpg';
// import p5 from 'assests/Photos/ClassAssembly/2024/10A/5.jpg';



// import p7 from 'assests/Photos/ClassAssembly/6D/7.jpg';





import { Typography } from '@mui/material';
import Container from 'components/Container';
import ClassAssembly2024 from '../ClassAssembly2024';
import Archive2022 from '../ClassAssembly2023/Archive2022';

const ClassAssembly10A2024 = () => {
  const theme = useTheme();
  const [currentImage, setCurrentImage] = useState(0);
  const [viewerIsOpen, setViewerIsOpen] = useState(false);

  const openLightbox = (index) => {
    setCurrentImage(index);
    setViewerIsOpen(true);
  };

  const closeLightbox = () => {
    setCurrentImage(0);
    setViewerIsOpen(false);
  };

  const isMd = useMediaQuery(theme.breakpoints.up('md'), {
    defaultMatches: true,
  });

  const [config, setConfig] = useState({ base_image_url: '' });
  useEffect(() => {
    fetch('/config.json')  // For Gatsby, this will resolve to /config.json from the static folder
      .then(response => response.json())
      .then(data => setConfig(data))
      .catch(error => console.error('Error fetching config:', error));
  }, []);
  
  const p1 = `${config.base_image_url}/home/events-activities/class-assembly/10A/1.webp`;
  const p2 = `${config.base_image_url}/home/events-activities/class-assembly/10A/2.webp`;
  const p3 = `${config.base_image_url}/home/events-activities/class-assembly/10A/3.webp`;
  const p4 = `${config.base_image_url}/home/events-activities/class-assembly/10A/4.webp`;
  const p5 = `${config.base_image_url}/home/events-activities/class-assembly/10A/5.webp`;
  



  const photos = [
    {
      src: p1,
      source: p1,
      rows: 1,
      cols: 1,
    },
    {
        src: p2,
        source: p2,
        rows: 1,
        cols: 1,
      },
    {
      src: p3,
      source:p3,
      rows: 1.5,
      cols: 2,
    },
   
    {
      src: p4,
      source: p4,
      rows: 1.5,
      cols: 2,
    },
    {
        src: p5,
        source: p5,
        rows: 1.5,
        cols: 2,
      },
      

    // {
    //   src: 'https://assets.maccarianagency.com/backgrounds/img21.jpg',
    //   source: 'https://assets.maccarianagency.com/backgrounds/img21.jpg',
    //   rows: 1,
    //   cols: 1,
    // },
  ];

  return (
    <Main>
        <Container>
    <Grid container spacing={4}>
            <Grid item xs={12} md={8}>
    <Box>
        
      <Box >
      <Typography variant='h4' align='center'>
      WHERE CREATIVITY MEETS FINANCE
        </Typography>
        <Typography
        fontSize = "x-small"
        ><br/><br/>
 Class: 10A Date: 2 July 2024

        </Typography>

        <br />
        <Typography variant={'subtitle1'} align={'justify'}>
        The students of class 10A showcased their creativity and artistry through their assembly ‘Advertisements’, on 2 July 2024. The presentation dealt with the major aspects of how advertisements work and how they influence people’s decisions and mind sets.
        <br></br>
        A fictional French restaurant was chosen as an appropriate example to demonstrate the topic. The students presented a skit adjoining a dance on a similar theme. A catchy name and tagline were provided to the restaurant chosen and a menu card was prepared with classic French dishes. This experience taught students teamwork and boosted their confidence. The assembly not only presented their skill and flair but also gave wonderful insights into the world of marketing and advertisements.
        
        </Typography>
      </Box>
      <Typography
          variant={'h6'}
          color={'primary'}
          align={'center'}
          fontWeight={'normal'}
        ><br/>
   “MANY A SMALL THING HAS BEEN MADE LARGE BY THE RIGHT KIND OF ADVERTISING”

<br/>
        </Typography>

    <Box>
        <ImageList
          variant="quilted"
          cols={2}
          rowHeight={isMd ? 200 : 100}
          gap={isMd ? 16 : 4}
        >
          {photos.map((item, i) => (
            <ImageListItem key={i} cols={item.cols} rows={item.rows}>
              <LazyLoadImage
                height={'100%'}
                width={'100%'}
                src={item.src}
                alt="..."
                effect="blur"
                onClick={() => openLightbox(i)}
                style={{
                  objectFit: 'cover',
                  filter:
                    theme.palette.mode === 'dark' ? 'brightness(0.7)' : 'none',
                  cursor: 'poiner',
                  borderRadius: 8,
                }}
              /> 
            </ImageListItem>
          ))}
        </ImageList>
      </Box>
      {viewerIsOpen && (
        <Lightbox
          mainSrc={photos[currentImage].src}
          nextSrc={photos[(currentImage + 1) % photos.length].src}
          prevSrc={
            photos[(currentImage + photos.length - 1) % photos.length].src
          }
          onCloseRequest={() => closeLightbox()}
          onMovePrevRequest={() =>
            setCurrentImage((currentImage + photos.length - 1) % photos.length)
          }
          onMoveNextRequest={() =>
            setCurrentImage((currentImage + 1) % photos.length)
          }
          reactModalStyle={{ overlay: { zIndex: 1500 } }}
        />
      )}
    </Box>
    </Grid>
    <Grid item xs={12} md={4}>
             
             <Box marginBottom={4}>
               <ClassAssembly2024 />
             </Box>
             <Box marginBottom={4}>
               <Archive2022 />
             </Box>
           {/* <SidebarNewsletter /> */}
         </Grid>
    </Grid> 
    </Container>
    </Main>
   
  );
};

export default ClassAssembly10A2024;